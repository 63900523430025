<template>
  <v-app>
    <div class="IdxList:">
      <div class="IdxList:container">
        <div class="IdxList:container40">
          <div class="IdxList:box poststable:">
            <div class="IdxList:box-wrap">
              <PerfIdxDatatable />
            </div>
          </div>
        </div>
        <Menu />
      </div>
    </div>
  </v-app>
</template>

<script>
import Menu from "@/components/general/nav/menu.vue";
import PerfIdxDatatable from "@/components/datatable/PerfIdxDatatable";

export default {
  data() {
    return {
    };
  },
  components: {
    Menu,
    PerfIdxDatatable,
  },
  watch: {
  },
  computed: {
    userid() {
      return this.$store.state.user.user.id;
    },
    headerConfig() {
      return this.$store.getters.getHeaderConfig;
    },
  },
  methods: {
    loggedIn() {
      return this.$store.getters.loggedIn;
    },
    reverseArr(input) {
      var ret = new Array();
      for (var i = input.length - 1; i >= 0; i--) {
        ret.push(input[i]);
      }
      return ret;
    },
  },
  created() {
    if (!this.loggedIn()) {
      this.$router.push("/login");
    } else {
    }
  },
  mounted() {
  },
};
</script>

<style lang="scss">
.IdxList\: {
  padding: 40px;
  height: 90%;
  &container {
    display: flex;
    height: 100%;
  }
  &box {
    color: white;
    padding: 15px;
    // background-image: url("~@/assets/images/general/sfondo.jpg");
    position: relative;
    &-wrap {
      border: var(--border);
      padding: 25px;
      height: 100%;
    }
    &-title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 0px;
    }
  }
  &container {
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
  }
  &container40 {
    width: 100%;
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
  }
  &container60 {
    width: 50%;
    display: flex;
    margin-left: -20px;
    margin-right: -20px;
    overflow-x: scroll;
    flex-direction: column;
  }
  &single {
    width: (100%/3);
    padding: 20px;
    &_val {
      color: rgb(255, 255, 255);
      font-weight: bold;
      font-size: 30px;
    }
  }

  .latestpost\: {
    width: 100%;
    .IdxList\:box-wrap {
      height: 460px;
    }
  }
  .uploadpost\: {
    width: 100%;
    .IdxList\:box-wrap {
      height: 230px;
    }
  }
  .poststable\: {
    width: 100%;
  }
}
</style>
