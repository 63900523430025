<template>
  <div class="">
    <v-card>
      <v-data-table
        dense
        :headers="outerHeaders"
        :items="indexes"
        :items-per-page="20"
        class="elevation-1"
        hide-default-footer
      >
        <template v-slot:top>
          <v-toolbar id="toolbar">
            <v-toolbar-title>
              <span class="px-3">Physical Efficiency Index</span>
            </v-toolbar-title>
          </v-toolbar>
        </template>

        <template v-slot:item="{ item }">
          <tr>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg0 == '' ? 'transparent' : item.bg0,
                color: item.bg0 == '' ? '' : 'black',
              }"
            >
              {{ item.col0 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg1 == '' ? 'transparent' : item.bg1,
                color: item.bg1 == '' ? '' : 'black',
              }"
            >
              {{ item.col1 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg2 == '' ? 'transparent' : item.bg2,
                color: item.bg2 == '' ? '' : 'black',
              }"
            >
              {{ item.col2 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg3 == '' ? 'transparent' : item.bg3,
                color: item.bg3 == '' ? '' : 'black',
              }"
            >
              {{ item.col3 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg4 == '' ? 'transparent' : item.bg4,
                color: item.bg4 == '' ? '' : 'black',
              }"
            >
              {{ item.col4 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg5 == '' ? 'transparent' : item.bg5,
                color: item.bg5 == '' ? '' : 'black',
              }"
            >
              {{ item.col5 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg6 == '' ? 'transparent' : item.bg6,
                color: item.bg6 == '' ? '' : 'black',
              }"
            >
              {{ item.col6 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg7 == '' ? 'transparent' : item.bg7,
                color: item.bg7 == '' ? '' : 'black',
              }"
            >
              {{ item.col7 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg8 == '' ? 'transparent' : item.bg8,
                color: item.bg8 == '' ? '' : 'black',
              }"
            >
              {{ item.col8 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg9 == '' ? 'transparent' : item.bg9,
                color: item.bg9 == '' ? '' : 'black',
              }"
            >
              {{ item.col9 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg10 == '' ? 'transparent' : item.bg10,
                color: item.bg10 == '' ? '' : 'black',
              }"
            >
              {{ item.col10 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg11 == '' ? 'transparent' : item.bg11,
                color: item.bg11 == '' ? '' : 'black',
              }"
            >
              {{ item.col11 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg12 == '' ? 'transparent' : item.bg12,
                color: item.bg12 == '' ? '' : 'black',
              }"
            >
              {{ item.col12 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg13 == '' ? 'transparent' : item.bg13,
                color: item.bg13 == '' ? '' : 'black',
              }"
            >
              {{ item.col13 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg14 == '' ? 'transparent' : item.bg14,
                color: item.bg14 == '' ? '' : 'black',
              }"
            >
              {{ item.col14 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg15 == '' ? 'transparent' : item.bg15,
                color: item.bg15 == '' ? '' : 'black',
              }"
            >
              {{ item.col15 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg16 == '' ? 'transparent' : item.bg16,
                color: item.bg16 == '' ? '' : 'black',
              }"
            >
              {{ item.col16 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg17 == '' ? 'transparent' : item.bg17,
                color: item.bg17 == '' ? '' : 'black',
              }"
            >
              {{ item.col17 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg18 == '' ? 'transparent' : item.bg18,
                color: item.bg18 == '' ? '' : 'black',
              }"
            >
              {{ item.col18 }}
            </td>
            <td
              class="text-center"
              :style="{
                backgroundColor: item.bg19 == '' ? 'transparent' : item.bg19,
                color: item.bg19 == '' ? '' : 'black',
              }"
            >
              {{ item.col19 }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
<style scoped></style>
<script>
import axios from "axios";
export default {
  name: "indexesDatatable",
  data() {
    return {
      indexes: [],
      loading: true,
      options: {},
      outerHeaders: [],
      refreshTimer: "",
      now: "",
    };
  },
  watch: {
    options: {
      handler() {
        this.readDataFromAPI();
      },
      dialogRestart(val) {
        val || this.closeRestart();
      },
    },
    deep: true,
  },
  computed: {
    userid() {
      return this.$store.state.user.user.id;
    },
    headerConfig() {
      return this.$store.getters.getHeaderConfig;
    },
    idgame() {
      return this.$store.state.idgame;
    },
    storeIdgame() {
      return this.$store.state.storeIdgame;
    },
  },
  methods: {
    async readDataFromAPI() {
      this.now = new Date().toLocaleString();
      this.loading = true;
      const { page, itemsPerPage } = this.options;

      // Recupera i dati editati
      const liveId = this.idgame != null ? this.idgame : this.storeIdgame;
      //console.log(process.env.VUE_APP_API_URL + "/gperfindexes/" + liveId);
      try {
        axios
          .get(process.env.VUE_APP_API_URL + "/gperfindexes/" + liveId)
          .then((response) => {
            this.loading = false;
            // Crea dati per header e tabella
            let rows = response.data;

            this.indexes = [];
            this.outerHeaders = [];
            let first = true;
            let numCols = 0;
            for (const row of rows) {
              if (first) {
                numCols = row.length;
                let colIdx = 0;
                for (const col of row) {
                  this.outerHeaders.push({
                    text: col.v,
                    value: "col" + colIdx++,
                    align: " center",
                    sortable: false,
                  });
                }
                first = false;
              } else {
                let colIdx = -1;
                let tableRow = {};
                for (const col of row) {
                  if (col.bg != "") {
                    if (!col.bg.hasOwnProperty("red")) col.bg.red = 0;
                    if (!col.bg.hasOwnProperty("green")) col.bg.green = 0;
                    if (!col.bg.hasOwnProperty("blue")) col.bg.blue = 0;
                    if (
                      col.bg.red == "1" &&
                      col.bg.green == "1" &&
                      col.bg.blue == "1"
                    )
                      col.bg = "";
                    colIdx++;
                    tableRow["col" + colIdx] = col.v;
                    tableRow["bg" + colIdx] =
                      col.bg == ""
                        ? ""
                        : "rgb(" +
                          col.bg.red * 255 +
                          "," +
                          col.bg.green * 255 +
                          "," +
                          col.bg.blue * 255 +
                          ")";
                  }
                }
                this.indexes.push(tableRow);
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log("GoogleDoc  error: ", error);
      }
    },
    handleClick(row) {
      //console.log(row.postId);
      //this.$emit("setPost", row.postId, row.hometeam, row.awayteam);
    },
    reloadPage() {
      window.location.reload();
    },
  },
  mounted() {},
  created() {
    this.readDataFromAPI();
    this.refreshTimer = setInterval(this.readDataFromAPI, 60000);
  },
  destroyed() {
    clearInterval(this.refreshTimer);
  },
};
</script>
